import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { useIntl } from "gatsby-plugin-react-intl"
import { Col, Container, Row } from "react-bootstrap"
import { Link } from "gatsby"
import { setSeoData } from "../services/seo.service"
import "./error.scss"
import bgd404 from "../assets/images/404.webp"
import Title from "../components/common/title"

const NotFoundPage = ({ pageContext }) => {
  const intl = useIntl()
  // const isSSR = typeof window === "undefined"

  const seoData = setSeoData({
    lang: intl.locale,
    title: "404",
    field_seo: {},
  })

  let link

  // Set homepage link
  switch (intl.locale) {
    case "fr":
      link = "/"
      break
    default:
      link = "/en"
  }

  return (
    <Layout pageClass="page404" pageContext={pageContext}>
      <Seo data={seoData} />
      <section
        className="blk-404"
        style={{ backgroundImage: "url(" + bgd404 + ")" }}
      >
        <Container>
          <Row>
            <Col>
              <div className="error-info">
                <Title
                  heading={1}
                  size={2}
                  extraClass="mainTitle"
                  text={intl.formatMessage({ id: "404.title" })}
                />
                <div className="description">
                  <p>{intl.formatMessage({ id: "404.description" })}</p>
                </div>
                <Link className="btn" to={link}>
                  <span>{intl.formatMessage({ id: "404.link_text" })}</span>
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  )
}

export default NotFoundPage
